<template>
    <div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div :class=" isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 0 }"
                       @click="activeTab = 0" id="tab-one-tab"
                       data-toggle="tab" href="#tab-one" role="tab" aria-controls="tab-one"
                       aria-disabled="true">{{ isPhisical ? t('GENERAL.PERSONAL_DATA') : 'Dados da Empresa' }}</a>
                </li>
            </div>
            <div :class=" isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link"
                       :class="{ active: activeTab == 1, 'required': isRequiredClassValidation('address_id')}"
                       @click.stop.prevent="validateSecondAbaClick"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS') }} </a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" v-if="isPhisical">
                <li class="nav-item" role="presentation">
                    <a class="nav-link"
                       :class="{ active: activeTab == 2, 'required': isRequiredClassValidation('comercial_address_id')}"
                       @click.stop.prevent="validateThirdAbaClick"
                       id="addresscompany-tab"
                       data-toggle="tab"
                       href="#addressCompany"
                       role="tab"
                       aria-controls="addressCompany"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS_COMPANY') }} </a>
                </li>
            </div>
            <div :class=" isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 3 }" @click.stop.prevent="validateAnexAbaClick"
                       id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document"
                       aria-disabled="true">{{ t('CLIENTS.DOCUMENT') }}</a>
                </li>
            </div>
        </ul>
        <form @submit.prevent="create">
            <div class="tab-content modal-row2 modal-height-max" id="myTabContent" v-if="client"
                 style="overflow-x: hidden">
                <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="tab-one"
                     role="tabpanel" aria-labelledby="allotment-tab">
                    <div class="modal-body">
                        <div class="row gutters">
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6" v-if="isPhisical">
                                <div class="form-group">
                                    <label data-cy="client_add_taxpayer" for="taxpayer"
                                           :class="{'required': isRequiredClassValidation('taxpayer')}"
                                    >CPF: </label>
                                    <input type="text" id="taxpayer" name="taxpayer" v-model="client.taxpayer"
                                           class="form-control"
                                           onpaste="return false"
                                           @change="findClient(client.taxpayer)" placeholder="000.000.000-00"
                                           ondrop="return false"
                                           v-mask="['###.###.###-##']"
                                           @keyup="validateTaxpayer(client.taxpayer)">
                                    <div v-if="invalid.taxpayer" class="validation">
                                        {{ t(invalid.taxpayer) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" v-if="!isPhisical">
                                <div class="form-group">
                                    <label data-cy="client_add_taxpayer" for="taxpayer"
                                           :class="{'required': isRequiredClassValidation('taxpayer')}"
                                    >CNPJ:</label>
                                    <input type="text" id="taxpayer" name="taxpayer" v-model="client.taxpayer"
                                           class="form-control"
                                           onpaste="return false"
                                           @change="findClient(client.taxpayer)"
                                           placeholder="00.000.000/0000-00"
                                           ondrop="return false"
                                           v-mask="['##.###.###/####-##']"
                                           @keyup="validateTaxpayer(client.taxpayer)">
                                    <div v-if="invalid.taxpayer" class="validation">
                                        {{ t(invalid.taxpayer) }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('birthday')}"
                                           v-if="isPhisical"
                                           for="birthday"
                                    >{{ t('CLIENTS.BIRTHDAY') }}: </label>
                                    <input data-cy="client_add_birthday"
                                           type="text"
                                           id="birthday"
                                           name="birthday"
                                           v-model="client.birthday"
                                           placeholder="dd-mm-aaaa"
                                           v-mask="['##-##-####']"
                                           class="form-control"
                                           @change="validateDate('birthday')"
                                    >
                                    <div v-if="invalid.birthday" class="validation">{{ invalid.birthday }}</div>
                                </div>
                            </div>
                            <div v-else class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label for="birthday"
                                           :class="{'required': isRequiredClassValidation('birthday')}"
                                    >{{ t('CLIENTS.FOUNDATION_DAY') }}: </label>
                                    <input data-cy="client_add_foundation_day" type="text" id="birthday"
                                           name="foundationDay"
                                           placeholder="dd-mm-aaaa"
                                           v-model="client.birthday"
                                           class="form-control"
                                           v-mask="['##-##-####']"
                                           @change="validateDate('birthday')"
                                    >
                                    <div v-if="invalid.birthday" class="validation">{{ invalid.birthday }}</div>
                                </div>
                            </div>
                            <div v-if="!isPhisical" class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('creci')}">{{
                                            t('CLIENTS.CRECI')
                                        }}: </label>
                                    <input type="text" id="creci" name="creci"
                                           v-model="client.creci" placeholder="Creci"
                                           class="form-control">
                                    <div v-if="invalid.creci" class="validation">{{ invalid.creci }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="isPhisical">
                                <div class="form-group">
                                    <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                                    <input data-cy="client_add_name" type="text" id="name" name="name"
                                           v-model="client.name"
                                           class="form-control"
                                           placeholder="Nome Completo">
                                    <div v-if="invalid.name" class="validation">{{ invalid.name }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="!isPhisical">
                                <div class="form-group">
                                    <label for="name"
                                           class="required"
                                    >{{ t('COMPANIES.NAME') }}: </label>
                                    <input type="text" id="name" name="name"
                                           v-model="client.corporate_name"
                                           class="form-control">
                                    <div v-if="invalid.name" class="validation">{{ invalid.name }}</div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-12"
                                 id="marital_status_block">
                                <div class="form-group">
                                    <label
                                            :class="{'required': isRequiredClassValidation('marital_status_id')}"
                                    >{{ t('CLIENTS.MARITAL_STATUS') }}: </label>
                                    <Select2 v-if="client?.marital_statuses?.name"
                                             id="marital_status_id" name="marital_status_id"
                                             v-model="client.marital_status_id"
                                             :placeholder="client?.marital_statuses?.name"
                                             :settings="maritalstatus"/>
                                    <Select2 v-else
                                             id="marital_status_id" name="marital_status_id"
                                             v-model="client.marital_status_id"
                                             placeholder="Selecione"
                                             :settings="maritalstatus"/>
                                    <div v-if="invalid.marital_status_id" class="validation">{{
                                            invalid.marital_status_id
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('sex')}">{{
                                            t('GENERAL.SEX')
                                        }}: </label>
                                    <Select2 :settings="{ width: '100%', dropdownParent: '#customModalTwo'}" id="sex"
                                             name="sex" v-model="client.sex" data-cy="client-sex"
                                             placeholder="Selecione"
                                             :options="options" @select="inputSex(client.sex), isCompany = false"/>
                                    <div v-if="invalid.sex" class="validation">{{ invalid.sex }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="!isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('trade_name')}"
                                           for="trade_name">{{ t('CLIENTS.TRADE_NAME') }}: </label>
                                    <input type="text" id="trade_name" name="trade_name" v-model="client.trade_name"
                                           class="form-control">
                                    <div v-if="invalid.trade_name" class="validation">{{ invalid.trade_name }}</div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': isRequiredClassValidation('rg')}"
                                           for="rg">RG: </label>
                                    <input type="text" id="rg" name="rg" v-model="client.rg" class="form-control"
                                           maxlength="15">
                                    <div v-if="invalid.rg" class="validation">{{ invalid.rg }}</div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('dispatch_date')}"
                                           for="dispatch_date">{{ t('CLIENTS.DISPATCH_DATE') }}: </label>
                                    <input type="text" id="dispatch_date" name="dispatch_date"
                                           placeholder="dd-mm-aaaa"
                                           v-mask="['##-##-####']"
                                           v-model="client.dispatch_date" class="form-control"
                                           @change="validateDate('dispatch_date')"
                                    >
                                    <div v-if="invalid.dispatch_date" class="validation">{{
                                            invalid.dispatch_date
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('dispatching_agency')}"
                                           for="dispatching_agency">{{ t('CLIENTS.DISPATCH_AGENCY') }}: </label>
                                    <input type="text" id="dispatching_agency" name="dispatching_agency"
                                           v-model="client.dispatching_agency" class="form-control"
                                    >
                                    <div v-if="invalid.dispatching_agency" class="validation">
                                        {{ invalid.dispatching_agency }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                 id="profession_block">
                                <label :class="{'required': isRequiredClassValidation('profession_id')}"
                                >{{ t('CLIENTS.PROFESSION') }}: </label>
                                <Select2 :settings="setting"
                                         @select="inputProfession(client.profession_id)"
                                         v-if="client?.profession_id && client?.professions?.termo"
                                         :placeholder="client.professions.termo"
                                         data-cy="client_add_profession"
                                         id="profession_id"
                                         name="profession"
                                         v-model="client.profession_id"
                                ></Select2>
                                <Select2 v-else
                                         :settings="setting"
                                         @select="inputProfession(client.profession_id)"
                                         data-cy="client_add_profession"
                                         id="profession_id"
                                         placeholder="Selecione"
                                         name="profession"
                                         v-model="client.profession_id"
                                ></Select2>
                                <div v-if="invalid.profession_id" class="validation">{{ invalid.profession_id }}</div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': isRequiredClassValidation('income')}"
                                           for="income">Renda: </label>
                                    <input type="text"
                                           id="income"
                                           name="income"
                                           v-money3="valueConfig"
                                           v-model.lazy="client.income"
                                           class="form-control"
                                           maxlength="15">
                                    <div v-if="invalid.income" class="validation">{{ invalid.income }}</div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': isRequiredClassValidation('income_family')}"
                                           for="income_family">Renda Familiar: </label>
                                    <input type="text" id="income_family"
                                           name="income_family"
                                           v-money3="valueConfig"
                                           v-model.lazy="client.income_family"
                                           class="form-control"
                                           maxlength="15">
                                    <div v-if="invalid.income_family" class="validation">{{
                                            invalid.income_family
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="form-group ">
                                    <label :class="{'required': isRequiredClassValidation('work_on_company')}"
                                           for="work_on_company">Empresa em que trabalha: </label>
                                    <input type="text"
                                           id="work_on_company"
                                           name="work_on_company"
                                           v-model="client.work_on_company"
                                           class="form-control"
                                           maxlength="128">
                                    <div v-if="invalid.work_on_company" class="validation">{{
                                            invalid.work_on_company
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('email')}"
                                           for="email">{{ t('GENERAL.EMAIL') }}: </label>
                                    <input data-cy="client_add_email" type="text" id="email" name="email"
                                           v-model="client.email"
                                           class="form-control"
                                           placeholder="exemplo@exem.com">
                                    <div v-if="invalid.email" class="validation">{{ invalid.email }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" id="block_responsable"
                                 v-if="!isPhisical">
                                <div class="form-group">
                                    <label>{{ t('GENERAL.RESPONSABLE') }}: </label>
                                    <select2 :settings="ajaxResponsable"
                                             v-if="client?.responsableName"
                                             :placeholder="client.responsableName"
                                             @select="selectedResponsableId"
                                    ></select2>
                                    <select2 v-else
                                             :settings="ajaxResponsable"
                                             placeholder="Selecione"
                                             @select="selectedResponsableId">
                                    </select2>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6" v-if="isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('nationality')}"
                                           for="nationality">{{ t('CLIENTS.NATIONALITY') }}: </label>
                                    <input type="text" id="nationality" name="nationalitynationality"
                                           v-model="client.nationality"
                                           class="form-control">
                                    <div v-if="invalid.nationality" class="validation">{{ invalid.nationality }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6" v-if="isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('naturalness')}"
                                           for="naturalness">{{ t('CLIENTS.BIRTH_PLACE') }}: </label>
                                    <input type="text" id="naturalness" name="naturalness" v-model="client.naturalness"
                                           class="form-control">
                                    <div v-if="invalid.naturalness" class="validation">{{ invalid.naturalness }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': isRequiredClassValidation('mother_name')}"
                                           for="mother_name">{{ t('CLIENTS.MOTHER_NAME') }}: </label>
                                    <input type="text" id="mother_name" name="mother_name" v-model="client.mother_name"
                                           class="form-control">
                                    <div v-if="invalid.mother_name" class="validation">{{ invalid.mother_name }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="isPhisical">
                                <div class="form-group">
                                    <label for="father_name"
                                           :class="{'required': isRequiredClassValidation('father_name')}"
                                    >{{ t('CLIENTS.FATHER_NAME') }}: </label>
                                    <input type="text" id="father_name" name="father_name" v-model="client.father_name"
                                           class="form-control">
                                    <div v-if="invalid.father_name" class="validation">{{ invalid.father_name }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                                <phone :required="isRequiredClassValidation('phones')"
                                       :phonesIndex="client.phones"
                                       @update="updatePhone($event)"></phone>
                                <div v-if="invalid.phones" class="validation">{{ invalid.phones }}</div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                <div class="form-group">
                                    <label for="signature"
                                           :class="{'required': isRequiredClassValidation('description')}"
                                    >Observação: </label>
                                    <editor :init="configTinyMce" v-model="client.description"
                                            id="description"
                                            @update:modelValue="summernoteChange($event)"
                                            tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                    >
                                    </editor>
                                    <div v-if="invalid.description" class="validation">{{ invalid.description }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }" id="localization"
                     role="tabpanel" aria-labelledby="localization-tab">
                    <address-generic v-if="address"
                                     :is-invalid="this.invalid.address"
                                     :is-cep-required="isRequiredClassValidation('address_id')"
                                     :addressIndex="address"
                                     @update="updateAddress($event)"
                    />
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab === 2, show: activeTab === 2 }"
                     id="comercialAddress" v-if="isPhisical"
                     role="tabpanel" aria-labelledby="comercialaddress-tab">
                    <address-generic v-if="activeTab == 2"
                                     :is-invalid="this.invalid.addressComercial"
                                     :addressIndex="comercialAddress"
                                     :is-cep-required="isRequiredClassValidation('comercial_address_id')"
                                     @update="updateComerciaAddress($event)"
                    />
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 3, show: activeTab == 3 }" id="document"
                     role="tabpanel" aria-labelledby="document-tab">
                    <div class="row w-100 ml-2">
                        <div class="card w-100 ">
                            <div class="card-body row gutters pb-2">
                                <div class="col-6">
                                    <label class="required">{{ t('CLIENTS.TYPE_ANEX') }}: </label>
                                    <Select2 :settings="{width: '100%'}" :options="optionsDriveType"
                                             placeholder="Selecione um Tipo de Anexo"
                                             id="typeAnex" name="typeAnex"
                                             v-model="anex.drive_files_type_id"
                                    ></Select2>
                                </div>
                                <div class="col-6 d-flex">
                                    <button class="btn btn-primary  ml-3 align-self-end"
                                            @click.prevent.stop="setFile" id="new_anex"
                                            :disabled="!anex.drive_files_type_id">
                                        <i class="icon-file_upload"></i> Selecionar Anexo
                                    </button>
                                </div>
                                <p class="ml-2 mb-0 red">Extensões Suportadas: .pdf; .png; .jpg; .jpeg</p>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row"
                             v-if="client?.documents?.length">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 font-bold pl-4">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.NAME')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.TYPE_ANEX')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.SIZE')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2 w-100"
                             v-for="(document, key) in client.documents" :key="key"
                             :class="key != 0 ? 'border-top2': ''">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="list-primary  text-left">
                                    {{ document.name }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <Select2 :settings="{width: '100%'}" :options="optionsDriveType"
                                         placeholder="Selecione um Tipo de Anexo"
                                         disabled
                                         :id="`typeAnex${key}`" :name="`typeAnex${key}`"
                                         v-model="document.drive_files_type_id"
                                ></Select2>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="list-primary  text-left">
                                    {{ formatSize(document.file_size) }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex">
                                <div class="list-primary  text-left pointer">
                                    <i class="icon-download pl-3" v-if="document.id"
                                       @click="downloadDocument(document)"></i>
                                </div>
                                <div class="list-primary pointer">
                                    <i class="icon-trash-2 pl-2" @click="documentDelete(document, key)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card w-100" v-if="!client?.documents?.length">
                            <div class="card-header"></div>
                            <div class="card-body pt-0">
                                <div class="text-center">
                                    <h5>Nenhum Anexo Foi Cadastrado</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="justify-content: space-between">
                <div>
                    <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                </div>
                <div>
                    <button class="btn btn-secondary mr-2" type="button" v-if="activeTab != 0"
                            @click="backActiveTab">{{ t('ALLOTMENT.PREVIOUS') }}
                    </button>
                    <button data-cy="client_add_next" class="btn btn-secondary mr-2" type="button"
                            v-if="activeTab !== 3"
                            @click="next()">{{ t('ALLOTMENT.NEXT') }}
                    </button>
                    <button data-cy="client_add_save" class="btn btn-primary" type="submit">
                        {{ t('ACTIONS.SAVE') }}
                    </button>
                </div>
            </div>
        </form>
        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event"
                      @import="uploadDocument($event)"></upload-modal>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import Clients from '@/services/Clients';
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Select2 from 'vue3-select2-component';
import Editor from '@tinymce/tinymce-vue';
import phone from '@/components/layouts/phoneComponent.vue';
import addressGeneric from '@/components/layouts/addressComponent.vue';
import Seller from "@/services/Seller";
import moment from 'moment';
import {Money3Directive} from 'v-money3';
import Drive from "../../services/Drive";
import DriveFilesType from '../../services/DriveFilesType';
import uploadModal from '../../components/layouts/uploadModal';
import AttributesValidationsMixin from '../../mixins/AttributesValidationsMixin';
import {mapState} from "vuex";
import City from '../../services/City';

export default {
    name: 'createClient',
    directives: {money3: Money3Directive},
    props: {
        clientIndex: {
            type: Object,
        },
        addressIndex: {
            type: Object
        },
        isRequiredData: {
            type: Boolean,
            default: false
        },
        isPhisicalIndex: {
            type: Boolean,
            default: true
        }
    },
    mixins: [Validate, AttributesValidationsMixin],
    components: {
        Select2,
        phone,
        addressGeneric,
        Editor,
        uploadModal,
    },

    computed: {
        hasDocument() {
            return this.client?.documents?.length;
        },
        ...mapState({
            itemsValidations: 'itemsValidations',
            itemsValidationsRequired: 'itemsValidationsRequired',
        }),
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            isPhisical: true,
            invalid: {},
            uploadFile: false,
            anex: {},
            optionsDriveType: [],
            optionsDriveTypeRequired: [],
            client: null,
            activeTab: 0,
            comercialAddress: {},
            address: null,
            isCompany: false,
            options: [],
            optionsMaritalStatus: [],
            configTinyMce: {
                selector: 'textarea',
                license_key: 'gpl',
                contextmenu: false,
                plugins: 'print preview searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern emoticons',
                menubar: 'edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                images_upload_url: '',
                setup: function (editor) {
                    editor.on('Paste', function (e) {
                        // Impede a colagem de imagens
                        var items = e.clipboardData.items;
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].type.indexOf('image/') !== -1) {
                                e.preventDefault(); // Impede a colagem de imagens
                                alert('Colar imagens não é permitido.'); // Mensagem opcional
                                return;
                            }
                        }
                    });
                    editor.on('drop', function (e) {
                        // Previne a ação padrão de arrastar e soltar
                        e.preventDefault();
                        e.stopPropagation();
                        // Se desejar, pode mostrar uma mensagem ao usuário
                        alert('Arrastar e soltar de imagens não é permitido.');
                    });
                },
                file_picker_callback: function () {
                    // Desabilitar o seletor de arquivos
                    return false;
                },
                images_upload_handler: function (blobInfo, success, failure) {
                    // Desabilitar completamente o upload de imagens
                    failure('Upload de imagem desabilitado');
                },
                image_advtab: false,  // Desabilitar a aba avançada de imagens
                image_title: false,   // Desabilitar a opção de título de imagem
                automatic_uploads: false, // Desabilita uploads automáticos
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            valueConfig: {
                suffix: '',
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                masked: true,
                min: 0,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 1,
            },
            setting: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 3 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                dropdownParent: '#profession_block',
                minimumInputLength: 3,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/profissoes/listar`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
            professions: [],
            isEdit: false,
            configEditor: {
                height: '200'
            },
            maritalstatus: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                dropdownParent: '#marital_status_block',
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/publica/estados-civis/listar`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    headers: {
                        'company-id': localStorage.getItem('companyId'),
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
            ajaxResponsable: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                dropdownParent: '#block_responsable',
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list?onlyResponsable=true`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
        }
    },

    mounted() {
        if (!this.isPhisicalIndex) {
            this.isPhisical = false;
            this.isCompany = true;
        }
        this.listFilesType();
        this.client = this.clientIndex;
        this.$store.commit('changeLoading', true);
        if (this.clientIndex.taxpayer?.length) {
            this.isPhisical = (this.clientIndex.taxpayer.length <= 14);
            if (!this.isPhisical) {
                this.client.corporate_name = this.client.name;
                this.isCompany = true;
            }
        }
        this.address = this.addressIndex;
        this.genOptions();
        this.isEdit = this.clientIndex.id ? true : false;
        this.$store.commit('changeLoading', false);
        this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
    },

    watch: {
        isPhisicalIndex: function (val) {
            if (!val) {
                this.isPhisical = false;
                this.isCompany = true;
            }
        },
        clientIndex: function () {
            this.$store.commit('changeLoading', true);
            this.activeTab = 0;
            this.isCompany = false;
            this.client.phones = [];
            this.client = this.clientIndex;
            this.address = this.addressIndex;
            this.isEdit = this.clientIndex.id ? true : false;
            if (this.clientIndex.taxpayer?.length) {
                this.isPhisical = (this.clientIndex.taxpayer.length <= 14);
                if (!this.isPhisical) {
                    this.isCompany = true;
                }
            }
            this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
            !this.isPhisical ? this.client.corporate_name = this.clientIndex.name : null;
            this.$store.commit('changeLoading', false);
        }
    },

    methods: {
        validateDate(context)
        {
            if (context === 'birthday'){
                let valid = moment(this.client.birthday, 'DD-MM-YYYY').isValid();
                if (!valid){
                    this.client.birthday = null;
                    this.invalid.birthday = 'Data Inválida'
                }else {
                    this.invalid.birthday = null;
                }
            }else if (context === 'dispatch_date'){
                let valid = moment(this.client.dispatch_date, 'DD-MM-YYYY').isValid();
                if (!valid){
                    this.client.dispatch_date = null;
                    this.invalid.dispatch_date = 'Data Inválida'
                }else {
                    this.invalid.dispatch_date = null;
                }
            }
        },
        selectedResponsableId({id, text}) {
            if (!id) {
                this.client.responsible_id = null;
                this.client.responsableName = null;
            } else {
                this.client.responsible_id = id;
                this.client.responsableName = text;
            }
        },
        next() {
            if (this.activeTab == 0) {
                this.validateSecondAbaClick();
            } else {
                this.validateLastAbaClick();
            }
        },
        async uploadDocument(file) {
            if (!file) return this.toast.error(this.t('CLIENTS.DOCUMENTS.FILE_EMPTY'));
            if (!this.anex.drive_files_type_id) return this.toast.error('Obrigatorio selecionar Tipo de Anexo');
            if (!this.client?.id) {
                if (!this.client?.documents?.length) {
                    this.client.documents = [];
                }
                await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                }).then(base64 => {
                    this.client.documents.push({
                        drive_files_type_id: this.anex.drive_files_type_id,
                        name: file.name,
                        file_size: file.size,
                        file: base64,
                        context_id: null,
                        context: 'clientes'
                    })
                });
                this.anex = {};
                return;
            }
            this.$store.commit('changeLoading', true);
            let formData = new FormData();
            formData.append('file', file);
            formData.append('context_id', this.client.id);
            formData.append('drive_files_type_id', this.anex.drive_files_type_id);
            formData.append('context', 'clientes');
            Drive.uploadDocument(formData).then((resp) => {
                if (resp.data.code) {
                    this.toast.success(resp.data.description);
                } else {
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.CREATED'));
                }
                if (!this.client?.documents?.length) {
                    this.client.documents = [];
                }
                this.client.documents.push(resp.data);
                this.anex = {};
                this.$store.commit('changeLoading', false);
            }).catch(err => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            }).finally(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        setFile() {
            this.uploadFile = true;
        },
        listFilesType() {
            DriveFilesType.list().then((resp) => {
                if (resp?.data?.length) {
                    resp.data.forEach((i) => {
                        if (i.required_on_person) {
                            this.optionsDriveTypeRequired.push(i);
                        }
                        this.optionsDriveType.push({id: i.id, text: i.text})
                    })
                }
            })
        },
        summernoteChange(newValue) {
            this.client.description = newValue;
        },

        validateSecondAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 1;
            }
        },
        validateFieldsTabeOne() {
            const tabOne = document.getElementById('tab-one');
            const selects = tabOne.querySelectorAll('select');
            const inputs = tabOne.querySelectorAll('input');
            const ids = [];
            let valid = true;
            inputs?.forEach(input => {
                ids.push(input.id);
            });
            selects?.forEach(input => {
                ids.push(input.id);
            });
            if (this.itemsValidationsRequired?.length && ids?.length) {
                ids.forEach(id => {
                    let iten = this.itemsValidationsRequired.find(i => i.name === id);
                    if (!iten) return
                    if (!this.client[iten.name]) {
                        document.getElementById(iten.name)?.classList?.add('is-invalid');
                        this.invalid[iten.name] = this.t('GENERAL.MSG.REQUIRED_FIELD');
                        valid = false;
                    } else {
                        this.invalid[iten.name] = false;
                        document.getElementById(iten.name)?.classList?.remove('is-invalid');
                    }
                })
            }
            if (this.isRequired('phones')) {
                if (!this.client.phones?.length) {
                    this.invalid.phones = this.t('GENERAL.MSG.REQUIRED_FIELD');
                    valid = false;
                    document.getElementById('phones')?.classList?.add('is-invalid');
                } else {
                    this.invalid.phones = false;
                    document.getElementById('phones')?.classList?.remove('is-invalid');
                }
            }
            if (this.isRequired('description')) {
                if (!this.client.description) {
                    document.getElementById('description')?.classList?.add('is-invalid');
                    this.invalid.description = this.t('GENERAL.MSG.REQUIRED_FIELD');
                    valid = false;
                } else {
                    this.invalid.description = false;
                    document.getElementById('description')?.classList?.remove('is-invalid');
                }
            }
            if (!this.client.name && this.isPhisical) {
                document.getElementById('name')?.classList?.add('is-invalid');
                this.invalid.name = this.t('GENERAL.MSG.REQUIRED_FIELD');
                valid = false;
            } else if (!this.client.corporate_name && !this.isPhisical) {
                document.getElementById('name')?.classList?.add('is-invalid');
                this.invalid.name = this.t('GENERAL.MSG.REQUIRED_FIELD');
                valid = false;
            } else {
                this.invalid.name = false;
                document.getElementById('name')?.classList?.remove('is-invalid');
            }

            if (!valid) {
                this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
            }
            return valid;
        },
        isRequired(field) {
            return !!this.itemsValidationsRequired.find(i => i.name === field);
        },
        validateThirdAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 2;
            }
        },
        validateAnexAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 3;
            }
        },
        inputProfession(profession) {
            if (profession) {
                this.client.profession_id = profession;
            }
        },
        formatSize(fileSize) {
            if (fileSize < 1000) return `${fileSize} Bytes`
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`
        },
        genOptions() {
            this.options = ["Masculino", "Feminino", "Outros"];
            this.optionsMaritalStatus = [
                "Solteiro(a)",
                "Casado(a)",
                "Separado(a)",
                "Divorciado(a)",
                "Viúvo(a)",
                "Amasiado(a)",
                "Separado(a) Judicialmente",
                "União estável",
                "Casado(a) no regime de comunhão parcial de bens",
                "Casado(a) no regime de comunhão universal de bens (Antes lei 6.515/77)",
                "Casado(a) no regime de comunhão universal de bens (Após lei 6.515/77)",
                "Casado(a) no regime de separação total de bens",
                "Casado(a) no regime de participação final nos aquestos"
            ]
        },

        backActiveTab() {
            if (this.activeTab === 3 && !this.isPhisical) {
                this.activeTab = 1;
            } else {
                this.activeTab--;
            }
        },
        validateLastAbaClick() {
            if (this.activeTab === 1 && !this.isPhisical) {
                this.activeTab = 3;
            } else {
                this.activeTab++;
            }
        },

        updateAddress(address) {
            this.client.address = address;
        },

        updateComerciaAddress(address) {
            this.client.comercial_address = address;
        },

        updatePhone(phones) {
            this.client.phones = phones;
        },
        validateTaxpayer(taxpayer) {
            if (this.isPhisical) {
                if (taxpayer.length) {
                    if (this.validateCpf(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CPF inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            } else {
                if (taxpayer.length) {
                    if (this.validateCNPJ(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CNPJ inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            }
        },
        async findClient(taxpayer) {
            if (this.isPhisical && taxpayer.length !== 14) {
                return;
            } else if (taxpayer.length !== 18) {
                return;
            }
            let tax = btoa(taxpayer);
            this.$store.commit("changeLoading", true);
            await Clients.showWhenCreate(tax).then(async (res) => {
                if (res.data.length !== 0) {
                    if (res.data.company_id === localStorage.getItem('companyId')) {
                        let resp = res.data;
                        this.client.taxpayer = resp.taxpayer;
                        this.isPhisical = (resp.taxpayer?.length <= 14)
                        this.$emit('to-update', res.data.id);
                    } else {
                        let resp = res.data;
                        this.client.taxpayer = resp.taxpayer;
                        this.client.name = resp.name;
                        this.client.email = resp.email;
                        this.client.rg = resp.rg;
                        this.client.dispatching_agency = resp.dispatching_agency;
                        this.client.dispatch_date = res.data.dispatch_date;
                        this.client.nationality = resp.nationality;
                        this.client.naturalness = resp.naturalness;
                        this.client.birthday = res.data.birthday;
                        this.client.father_name = resp.father_name;
                        this.client.sex = resp.sex;
                        this.client.profession_id = resp.professions.id
                        this.client.mother_name = resp.mother_name;
                        this.address = resp.address;
                        if (this?.address?.cep) {
                            this.client.address = this.address;
                        }
                        this.toast.success(this.t("GENERAL.MSG.CHECK_YOUR_DATA_AND_CONTINUE"), {timeout: false});
                        this.toast.success(this.t("GENERAL.MSG.CLIENT_LOCATED_IN_THE_DATABASE"));
                        this.client.phones = resp.phones;
                        this.$store.commit('changeLoading', false)
                    }
                } else {
                    taxpayer = taxpayer.replaceAll(".", "");
                    taxpayer = taxpayer.replaceAll("-", "");
                    taxpayer = taxpayer.replaceAll("/", "");
                    if (taxpayer.length === 14) {
                        this.$store.commit("changeLoading", true);
                        await Seller.loadCnpj(taxpayer).then(async (resp) => {
                            this.client.corporate_name = resp.data.razao_social_nome_empresarial;
                            this.client.email = resp.data.estabelecimento[0]?.correio_eletronico;
                            this.client.birthday = this.formaterBirthDay(resp.data.estabelecimento[0]?.data_inicio_atividades);
                            this.client.trade_name = resp.data.estabelecimento[0]?.nome_fantasia;
                            this.address.street = resp.data.estabelecimento[0]?.logradouro;
                            this.address.stateName = resp.data.estabelecimento[0]?.uf;
                            this.address.cep = resp.data.estabelecimento[0]?.cep;
                            this.address.neighborhood = resp.data.estabelecimento[0]?.bairro;
                            this.address.complement = resp.data.estabelecimento[0]?.complemento;
                            this.address.cityName = resp.data.estabelecimento[0]?.municipio.descricao;
                            this.address.number = resp.data.estabelecimento[0]?.numero;
                            if (this?.address?.cep) {
                                await City.listByCepDisp(this.address.cep).then(async (resp) => {
                                    if (!resp.data.erro) {
                                        this.address.city_id = resp.data.id;
                                        this.address.stateName = resp.data.name;
                                        this.address.cityName = resp.data.localidade;
                                        this.$emit('update', this.address);
                                    }
                                })
                                this.client.address = this.address;
                                if (!this.client?.address?.city_id) {
                                    this.client.address = {};
                                    this.address = {};
                                }
                            } else {
                                this.client.address = {};
                                this.address = {};
                            }
                            if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd1}${resp.data.estabelecimento[0]?.telefone1}`)) {
                                this.client.phones = [{
                                    number: (`(${resp.data.estabelecimento[0].ddd1}) ` + resp.data.estabelecimento[0].telefone1),
                                    type: '2'
                                }];
                            } else {
                                this.client.phones = [{
                                    number: (`(${resp.data.estabelecimento[0]?.ddd1}) ` + resp.data.estabelecimento[0]?.telefone1),
                                    type: '1'
                                }];
                            }
                            if (resp.data.estabelecimento[0]?.ddd2) {
                                if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd2}${resp.data.estabelecimento[0]?.telefone2}`)) {
                                    this.client.phones.push({
                                        number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                        type: '2'
                                    });
                                } else {
                                    this.client.phones.push({
                                        number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                        type: '1'
                                    });
                                }
                            }
                            this.$store.commit("changeLoading", false);
                        }).catch((error) => {
                            console.log(error);
                            this.$store.commit("changeLoading", false)
                        });
                    }
                }
            }).catch((er) => {
                this.$store.commit('changeLoading', false)
                console.log(er)
            }).finally(() => this.$store.commit("changeLoading", false))
        },

        async checkNumberPhone(number) {
            let isValid = false
            await Clients.numberValid(number).then(response => {
                isValid = response.data.numberExists;
            })
            return isValid;
        },

        formaterBirthDay(date) {
            if (!date) {
                return null;
            }
            date = date.split('/');
            return `${date[2]}-${date[1]}-${date[0]}`;
        },

        formatterValue(value) {
            if (!value) return value;
            value = value.replace('R$ ', '');
            value = value.replaceAll(".", "");
            value = value.replaceAll(",", ".");
            value = parseFloat(value).toFixed(2);
            if (value < 1) {
                return 0;
            }
            return value;
        },

        create() {
            if (this.client.profession_id === "0") {
                this.client.profession_id = null;
            }
            if (this.optionsDriveTypeRequired?.length) {
                let invalidDrive = false;
                this.optionsDriveTypeRequired.forEach(i => {
                    let has = false;
                    this.client?.documents?.forEach(d => {
                        if (parseInt(d.drive_files_type_id) === i.id) {
                            has = true;
                        }
                    });
                    if (!has) {
                        this.toast.error(`Obrigatorio informar um Anexo do tipo: ${i.text}`);
                        invalidDrive = true;
                    }
                });
                if (invalidDrive) {
                    return;
                }
            }
            if (this.checkForm()) {
                this.$store.commit('changeLoading', true);
                this.client.income = this.formatterValue(this.client.income);
                this.client.income_family = this.formatterValue(this.client.income_family);
                if (this.client.id) {
                    if (this.client.corporate_name) {
                        this.client.name = this.client.corporate_name
                        delete this.client.corporate_name;
                    }
                    if (this.client.phones) {
                        this.client.phones = this.client.phones.map(item => {
                            if (!item.number) {
                                item.number = '0';
                            }
                            return item;
                        })
                    }
                    if (this.isPhisical) {
                        this.client.type = 'phisical';
                    } else this.client.type = 'juridical';
                    if (!this.client?.address?.city_id) {
                        this.client.address = {};
                        this.address = {};
                    }
                    Clients.update(this.client).then(() => {
                        this.$emit('update');
                        this.toast.success(this.t("GENERAL.MSG.UPDATE_SUCCESS"));
                        this.$store.commit('changeLoading', false);
                        document.getElementById('closeX').click();
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                } else {
                    if (this.client.corporate_name) {
                        this.client.name = this.client.corporate_name
                    }

                    if (this.isPhisical) {
                        this.client.type = 'phisical';
                    } else this.client.type = 'juridical';
                    if (!this.client?.address?.cep?.length) {
                        delete this.client.address;
                    }
                    if (!this.client?.comercial_address?.cep?.length) {
                        delete this.client.comercial_address;
                    }
                    if (!this.client?.address?.city_id) {
                        this.client.address = {};
                        this.address = {};
                    }
                    Clients.create(this.client).then(() => {
                        this.$emit('update');
                        this.toast.success(this.t("GENERAL.MSG.REGISTER_SUCCESS"));
                        this.$store.commit('changeLoading', false);
                        document.getElementById('closeX').click();
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                }
            }
        },

        checkForm() {
            if (this.itemsValidationsRequired.length) {
                if (!this.validateFieldsTabeOne()) return false;
                let havNotField = false;
                if (this.isRequired('address_id')) {
                    let adr = this.address;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.address = {};
                        this.invalid.address.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.address = false;
                    }
                }
                if (this.isRequired('comercial_address_id') && this.isPhisical) {
                    let adr = this.comercialAddress;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.addressComercial = {};
                        this.invalid.addressComercial.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.addressComercial = false;
                    }
                }
                if (havNotField) {
                    this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
                    return false;
                }
            }

            if (!this.client.email) {
                this.client.email = null;
            } else {
                this.client.email = this.client.email.trim();
            }

            let name = '';
            if (this.isPhisical) {
                name = this.client.name;
            } else name = this.client.corporate_name;

            if (!name) {
                this.invalid.name = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length < 2) {
                this.invalid.name = this.t("GENERAL.MSG.MINIMUM_CHARACTER");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length > 255) {
                this.invalid.name = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("name")?.classList.add("is-invalid");
                return false;
            } else {
                this.invalid.name = false;
                document.getElementById("name")?.classList?.add("is-valid");
            }
            return true;
        },

        downloadDocument(doc) {
            this.$store.commit('changeLoading', true);
            Drive.download(doc.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.name);
                link.click();
                this.$store.commit('changeLoading', false);
            }).catch(() => this.$store.commit('changeLoading', false));
        },

        documentDelete(anex, key) {
            if (anex.id) {
                this.$store.commit('changeLoading', true);
                Drive.fileDelete(anex.id).then(() => {
                    this.client.documents = this.client.documents.filter(document => {
                        if (document.id !== anex.id) return document;
                    })
                    if (!this.client.documents.length) this.activeTab--;
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.DELETE_SUCCESSFULL'));
                    this.$store.commit('changeLoading', false);
                }).catch(err => {
                    this.errorMsg(err);
                    this.$store.commit('changeLoading', false);
                });
            } else {
                this.client.documents = this.client.documents.filter((document, k) => {
                    if (k !== key) return document;
                })
            }
        }
    }
}
</script>

<style scoped>
.btn-holver {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.custom-checkbox {
    padding-left: 32px !important;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 424px) {
    .mt-m-2 {
        margin-top: 1.1rem !important;
    }
}
</style>
